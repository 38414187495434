/* eslint-disable */
<template>
	<div
		class="bg-dark"
		:style="
			`background-image: url(${backgroundImg}); background-size: cover; background-position: center`
		"
	>
		<div class="py-6" v-if="materialCompleted">
			<div class="container">
				<b-alert class="mb-0" show variant="success"
					>You have completed this course on
					<em>{{ materialCompletedObj.completion_date }}</em>
				</b-alert>
			</div>
		</div>
		<PdfComponent
			v-if="lessonType === 'pdf'"
			:material-completed="materialCompleted"
			:lesson-completed="lessonCompleted"
			:trainee-course-id="$route.params.traineeCourseId"
		></PdfComponent>
		<VideoComponent
			:trainee-course-id="$route.params.traineeCourseId"
			v-if="lessonType === 'video'"
			:material-completed="materialCompleted"
			:lesson-completed="lessonCompleted"
			:material-id="this.$route.params.lessonId"
		></VideoComponent>
		<QuizComponent
			:trainee-course-id="$route.params.traineeCourseId"
			v-if="lessonType === 'quiz'"
			:material-completed="materialCompleted"
			:lesson-completed="lessonCompleted"
			:training-obj="quizQuestionList"
		></QuizComponent>
	</div>
</template>

<script>
/* eslint-disable */
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";

import PdfComponent from "@/core/components/PdfComponent";
import VideoComponent from "@/core/components/VideoComponent";
import QuizComponent from "@/core/components/QuizComponent";
import {
	GET_TRAINEE_MODULE_PROGRESS,
	SET_CURRENT_ACTIVE_LESSON,
	SET_CURRENT_LESSON_STATE,
	GET_VIEW_COURSE_PROGRESS,
} from "@/core/services/store/modules/trainee/course.module";

import VideoPlayer from "@/view/layout/extras/videojs/VideoPlayer.vue";

export default {
	components: { QuizComponent, VideoComponent, PdfComponent, VideoPlayer },
	async mounted() {
		// console.log("Module ID: ", this.$route.params.moduleId);

		switch (this.$route.params.lessonType) {
			case "quiz":
				this.lessonType = "quiz";
				break;
			case "mp4":
				this.lessonType = "video";
				break;
			case "pdf":
				this.lessonType = "pdf";
				break;

			default:
		}

		let payload = {
			trainee_course_id: this.$route.params.traineeCourseId,
		};
		await this.$store
			.dispatch(GET_TRAINEE_MODULE_PROGRESS, payload)
			.then((res) => {
				if (res.response_code === 2100) {
					console.log("training module progress success");
				} else {
					if (res.response_code === 10055) {
						this.materialCompletedObj =
							res.data.traineeCourseComplete;
						this.materialCompleted = true;
					}
				}
			});

		console.log(this.$route.params);

		if (this.$route.params.lessonObj === undefined) {
			console.log("this.$route.params.lessonObj undefined");
			let x = window.localStorage.getItem("currentActiveModule");
			if (x !== null) {
				console.log("key exist");
				// x = window.localStorage.getItem("currentActiveModule")
				await this.$store.dispatch(
					SET_CURRENT_LESSON_STATE,
					JSON.parse(x)
				);
			} else {
				console.log("key not exist");
				await this.$router.push({
					name: "trainee-course",
					params: {
						traineeCourseId: this.$route.params.traineeCourseId,
					},
				});
			}
		} else {
			console.log("this.$route.params.lessonObj not undefined");
			await this.$store.dispatch(
				SET_CURRENT_LESSON_STATE,
				this.$route.params.lessonObj
			);
		}

		// get course progress data
		let courseProgressPayload = {
			trainee_course_id: this.$route.params.traineeCourseId,
		};
		await this.$store
			.dispatch(GET_VIEW_COURSE_PROGRESS, courseProgressPayload)
			.then((res) => {
				if (res.response_code == 2100) {
					console.log("Current Module: ", this.CurrentModule);
					console.log("Current Lesson: ", this.CurrentLesson);
					console.log("FinalCheckData: ", this.FinalCheckData);

					// flag lessonCompleted
					if (this.CurrentLesson.course_progress_status_id == 1) {
						this.lessonCompleted = true;
					}

					if (
						this.FinalCheckData.trainee_module_item_id &&
						this.CurrentLesson.course_progress_status_id != 1
					) {
						// check if current module is the last module in course
						let isNotfinalModule = this.CourseStructure.find(
							(module) =>
								module.order_no > this.CurrentModule.order_no
						);
						this.isFinalModule = isNotfinalModule ? false : true;

						// check if current less is the last lesson in module
						const currentLesson = this.CurrentLesson;
						let isNotFinalLesson = this.CurrentModule.trainee_module_items.find(
							(lesson) => lesson.order_no > currentLesson.order_no
						);
						this.isFinalLesson = isNotFinalLesson ? false : true;
						console.log("Final Module && Lesson Check");
						console.log("isFinalModule1: ", this.isFinalModule);
						console.log("isFinalLesson1: ", this.isFinalLesson);
					} else {
						console.log("Did not check for Last Module && Lesson");
						console.log("isFinalModule1: ", this.isFinalModule);
						console.log("isFinalLesson1: ", this.isFinalLesson);
					}
				}
			});
	},
	computed: {
		CourseProgress() {
			const course = this.$store.state.trainee_course
				.courseProgressDetail;
			if (typeof course === "object" && course !== null) {
				return course;
			} else {
				return {};
			}
		},
		CourseStructure() {
			const course = this.CourseProgress;
			if (Object.keys(course).length) {
				return course.structure;
			} else {
				return [];
			}
		},
		CurrentModule() {
			const cModuleId = this.FinalCheckData.trainee_module_id;
			const modules = this.CourseStructure;

			// filter current module
			let currentModule = modules.find(
				(module) => module.trainee_module_id == cModuleId
			);
			if (currentModule) {
				return currentModule;
			} else {
				return {};
			}
		},
		CurrentLesson() {
			const currentLesson = this.CurrentModule.trainee_module_items.find(
				(item) => {
					return (
						item.trainee_module_item_id ==
						this.FinalCheckData.trainee_module_item_id
					);
				}
			);
			if (typeof currentLesson === "object" && currentLesson !== null) {
				return currentLesson;
			} else {
				return {};
			}
		},
		FinalCheckData() {
			const data = this.$store.state.trainee_course.finalCheckData;
			if (typeof data === "object" && Object.keys(data).length) {
				return data;
			} else {
				return {};
			}
		},
		backgroundImg() {
			return "/assets/img/placeholders/dashboard_trainee_bg01.jpg";
		},
		quizQuestionList() {
			let x = this.$store.state.trainee_course.quizQuestionList;
			if (x) {
				return x;
			} else {
				return {};
			}
		},
	},
	beforeDestroy() {
		this.$store.dispatch(SET_CURRENT_LESSON_STATE, null);
	},
	methods: {},
	data() {
		return {
			lessonCompleted: false,
			materialCompletedObj: null,
			materialCompleted: false,
			lessonType: null,
			isFinalModule: null,
			isFinalLesson: null,
		};
	},
};
</script>
