<template>
	<b-modal
		:id="modalId"
		:visible="modalVisibility(modalId)"
		@show="onShow()"
		@hidden="resetModal()"
		title="Pledge"
		size="lg"
		:centered="true"
		scrollable
	>
		<div>
			<ol>
				<li>
					I/We shall respect the law at all times and fully abide by
					all applicable laws, rules, procedures and policies on
					corruption prevention and abuse of power in whichever
					country I/we operate;
				</li>
				<li>
					I/We have read, understand and agree to comply with
					{{ companyName }}’s Anti-Bribery & Corruption Policy and
					Code of Conduct & Business Ethics as supplemented alongside
					this pledge when carrying outmy/our responsibilities for, on
					behalf and/or in relation to {{ companyName }};
				</li>
				<li>
					I/We shall abhor and reject all forms of corruption and
					abuse of power, and shall give my/our full cooperation to
					the Malaysian Anti-Corruption Commission in preventing
					corruption and abuse of power;
				</li>
				<li>
					I/We shall not indulge in any form of corruption and abuse
					of power either directly or indirectly, with any party
					dealing with {{ companyName }} Group or the Company;
				</li>
				<li>
					I/We shall take proactive steps to ensure that I/my/our
					employees shall not indulge in any act of corruption and
					abuse of power in any dealings concerning
					{{ companyName }} Group or the Company;
				</li>
				<li>
					I/We shall instantly report any form of corruption and abuse
					of power whether involving {{ companyName }} Group or the
					Company’s directors or employees, to the Malaysian
					Anti-Corruption Commission.
				</li>
			</ol>
		</div>
		<b-form-checkbox v-model="isAgree" :disabled="isLoading">
			I accept the agreement
		</b-form-checkbox>

		<template #modal-footer>
			<b-button
				@click="onAgree()"
				:disabled="!isAgree || isLoading"
				variant="primary"
			>
				OK
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import { SUBMIT_PLEDGE } from "@/core/services/store/modules/trainee/course.module";
import { SET_MODAL_STATE } from "@/core/services/store/modules/ui.module";

export default {
	data() {
		return {
			modalId: "modal-course-pledge",
			isLoading: false,
			isAgree: false,
			companyName: "COMPANY",
		};
	},
	props: {
		courseId: { required: true },
	},
	methods: {
		onShow() {
			this.isLoading = false;
			console.log(this.courseId);
		},
		async onAgree() {
			this.isLoading = true;

			// submit pledge
			const payload = {
				trainee_course_id: this.courseId,
			};
			await this.$store.dispatch(SUBMIT_PLEDGE, payload).then((res) => {
				if (res.response_code == 2100) {
					this.$emit("agree");
					this.resetModal();
				}
				this.isLoading = false;
			});
		},
		resetModal() {
			this.isAgree = false;
			this.$store.dispatch(SET_MODAL_STATE, null);
		},
	},
};
</script>

<style></style>
