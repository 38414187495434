<template>
	<div class="position-relative">
		<video
			id="videoElement"
			controls
			ref="videoPlayer"
			:poster="parseVidPoster"
			class="video-js vjs-fluid"
			@canplay="updatePaused"
			@playing="updatePaused"
			@pause="updatePaused"
			@ended="onPlayerEnded($event)"
			@ready="playerReadied"
			@timeupdate="onPlayerTimeupdate($event)"
		>
			<template>
				<source special :src="parseVidSrc" type="video/mp4" />
			</template>

			<p>
				Sorry, there's a problem playing this video. Please try using a
				different browser.
			</p>
		</video>
		<div
			class="
        video-overlay
        position-absolute
        zindex-5
        left-0
        right-0
        top-0
        bottom-0
      "
			v-if="playbackEnded"
			style="background-color: rgba(0, 0, 0, 0.5)"
		>
			<div
				class="controls text-center w-100 position-absolute"
				style="top: 40%"
			>
				<h4 class="p-5 glow">You have completed the material</h4>
				<b-button class="h4" @click="play"> Replay Lesson </b-button>

				<!--if completed -->
				<b-button
					v-if="
						materialCompleted ||
							!materialProgressStatus.course_progress_status_id ||
							materialProgressStatus.course_progress_status_id ===
								1 ||
							lessonCompleted
					"
					class="h4 ml-3"
					variant="primary"
					@click="backToCourse()"
					>Back to Course page
				</b-button>
				<!--if ongoing-->
				<b-button
					v-if="
						(!materialCompleted ||
							materialProgressStatus.course_progress_status_id ===
								2 ||
							materialProgressStatus.course_progress_status_id ===
								3) &&
							!lessonCompleted
					"
					class="h4 ml-3"
					variant="primary"
					@click="submitLesson"
					>Complete Lesson
				</b-button>
			</div>
		</div>
		<div
			class="
        video-overlay
        position-absolute
        zindex-5
        left-0
        right-0
        top-0
        bottom-0
      "
			v-if="paused && !playbackEnded"
			style="background-color: rgba(0, 0, 0, 0.5)"
		>
			<div
				class="controls text-center w-100 position-absolute"
				style="top: 40%"
				v-if="!playbackAtStart"
			>
				<h4 class="text-light p-5 glow">Taking a break?</h4>
				<b-button class="h4" @click="play">
					Play
					<b-icon
						variant="dark"
						size="lg"
						icon="play-circle-fill"
					></b-icon>
				</b-button>
			</div>
			<div
				class="controls text-center w-100 position-absolute"
				style="top: 40%"
				v-if="playbackAtStart"
			>
				<h4 class="text-light p-5 glow">Ready to learn new lesson?</h4>
				<b-button class="h4" @click="play">
					Play
					<b-icon
						variant="dark"
						size="lg"
						icon="play-circle-fill"
					></b-icon>
				</b-button>
			</div>
		</div>
		<div class="d-none">
			{{ materialDetail }}
		</div>

		<!-- modals -->
		<course-pledge-modal
			:course-id="$route.params.traineeCourseId"
			@agree="completeLesson()"
		></course-pledge-modal>
	</div>
</template>

<script>
import videojs from "video.js";
import SystemHelper from "@/core/services/systemhelper.service";
import {
	GET_TRAINEE_MODULE_PROGRESS,
	GET_VIEW_MATERIAL,
	START_RESUME_COURSE,
	SUBMIT_COURSE_PROGRESS,
	SAVE_FINAL_CHECK_DATA,
} from "@/core/services/store/modules/trainee/course.module";
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";

import CoursePledgeModal from "@/view/pages/traineeuser/modals/CoursePledgeModal.vue";

export default {
	name: "VideoPlayer",
	components: { CoursePledgeModal },
	props: {
		materialCompleted: { required: true },
		lessonCompleted: { required: true },
		traineeCourseId: {
			required: true,
		},
		materialId: {
			required: true,
		},
	},
	data() {
		return {
			videoOptions: {
				autoplay: false,
				controls: true,
			},
			playbackAtStart: true,
			playbackEnded: false,
			showingOverlay: false,
			videoElement: null,
			paused: null,
			player: null,
		};
	},
	async mounted() {
		let payload = {
			material_id: this.materialId,
		};
		await this.$store.dispatch(GET_VIEW_MATERIAL, payload).then((res) => {
			console.log(res);
			this.initPlayer();
			this.player = videojs(
				this.$refs.videoPlayer,
				this.videoOptions,
				function onPlayerReady() {}
			);
		});
	},
	computed: {
		currentActiveLessonName() {
			let currentActiveLesson = this.$store.state.trainee_course
				.currentActiveLesson;
			if (currentActiveLesson) {
				return currentActiveLesson.material_name;
			} else {
				return "Lesson Name";
			}
		},
		materialDetail() {
			let material = this.$store.state.trainee_course.currentActiveLesson;

			if (material) {
				this.$store.dispatch(SET_BREADCRUMB, [
					{ title: "Lesson" },
					{ title: material.material_name },
				]);
				return material;
			} else {
				return {};
			}
		},
		materialProgressStatus() {
			let material = this.$store.state.trainee_course.currentActiveLesson;

			if (material) {
				return {
					course_progress_status: material.course_progress_status,
					course_progress_status_id:
						material.course_progress_status_id,
				};
			} else {
				return {};
			}
		},
		parseVidSrc() {
			let trainingLessonObj = this.$store.state.trainee_course
				.materialDetail;
			if (trainingLessonObj) {
				return trainingLessonObj.link;
			} else {
				return "Error";
			}
		},
		parseVidPoster() {
			return "/assets/img/placeholders/35.jpg";
		},
		PublicPath() {
			return process.env.BASE_URL;
		},
		playing() {
			return !this.paused;
		},
	},
	methods: {
		initPlayer() {},
		backToCourse() {
			this.$router.push({
				name: "trainee-course",
				traineeCourseId: this.traineeCourseId,
			});
		},
		startResumeCourse() {
			let payload = { trainee_course_id: this.traineeCourseId };

			this.$store
				.dispatch(GET_TRAINEE_MODULE_PROGRESS, payload)
				.then((res) => {
					console.log(res);
				});

			this.$store.dispatch(START_RESUME_COURSE, payload).then((res) => {
				if (res.response_code === 2100) {
					console.log(res);
				} else {
					SystemHelper.errorHandler(res, null, "error");
				}
			});
		},
		submitLesson() {
			let isFinalModule = this.$parent.$parent.isFinalModule;
			let isFinalLesson = this.$parent.$parent.isFinalLesson;
			if (isFinalModule && isFinalLesson) {
				this.openModal("modal-course-pledge");
			} else {
				this.completeLesson();
			}
		},
		async completeLesson() {
			let payload = {
				trainee_course_id: this.traineeCourseId,
				trainee_module_progress_id: this.materialDetail
					.trainee_module_progress_id,
			};

			await this.$swal
				.fire({
					title: "Complete Lesson",
					html: "Are you sure?",
					confirmButtonText: "OK",
					heightAuto: false,
					showCancelButton: false,
					customClass: {
						container: "confirmation-swal",
						header: "bg-info",
						confirmButton: "btn btn-sm btn-primary ml-3",
						cancelButton: "btn btn-sm btnーsecondary ml-3",
					},
				})
				.then((result) => {
					if (result.isConfirmed) {
						let _this = this;
						this.$store
							.dispatch(SUBMIT_COURSE_PROGRESS, payload)
							.then((res) => {
								console.log("res", res);
								if (res.response_code === 2100) {
									let payload = {
										trainee_course_id: this.$route.params
											.traineeCourseId,
									};
									this.$store
										.dispatch(START_RESUME_COURSE, payload)
										.then((res) => {
											if (res.response_code === 2100) {
												if (
													res.response_code === 2100
												) {
													let data = res.data.output;
													console.log("data", data);

													// let localArray = this.CourseProgressObj;
													//
													// let result = localArray.filter((module) => {
													//   return module.trainee_module_items.some(
													//     (trainee_module_item) => {
													//       return (
													//         trainee_module_item.trainee_module_item_id ===
													//         data.trainee_module_item_id
													//       );
													//     }
													//   );
													// });

													let pushParams;
													console.log({
														...data.material_detail,
														trainee_module_progress_id:
															data.trainee_module_progress_id,
													});
													if (data.material_id) {
														pushParams = {
															lessonType:
																data
																	.material_detail
																	.file_type,
															lessonId:
																data.material_id,
															lessonObj: {
																...data.material_detail,
																trainee_module_progress_id:
																	data.trainee_module_progress_id,
															},
														};
													} else {
														pushParams = {
															lessonType: "quiz",
															lessonId:
																data.quiz_id,
														};
													}

													// save finalCheckData
													this.$store.dispatch(
														SAVE_FINAL_CHECK_DATA,
														{
															trainee_module_item_id:
																data.trainee_module_item_id,
															trainee_module_id:
																data.trainee_module_id,
														}
													);

													_this.$router.push({
														name: "trainee-lesson",
														params: {
															traineeCourseId: this
																.$route.params
																.traineeCourseId,
															...pushParams,
														},
													});
												}
											} else {
												if (
													res.response_code === 10055
												) {
													this.$swal
														.fire({
															title:
																"Congratulation",
															html:
																"You have completed the course",
															confirmButtonText:
																"OK",
															heightAuto: false,
															showCancelButton: false,
															customClass: {
																container:
																	"confirmation-swal",
																header:
																	"bg-info",
																confirmButton:
																	"btn btn-sm btn-primary ml-3",
																cancelButton:
																	"btn btn-sm btnーsecondary ml-3",
															},
														})
														.then((result) => {
															if (
																result.isConfirmed
															) {
																_this.$router.push(
																	{
																		name:
																			"trainee-course",
																		params: {
																			traineeCourseId: this
																				.$route
																				.params
																				.traineeCourseId,
																		},
																	}
																);
															}
														});
												} else {
													SystemHelper.errorHandler(
														res,
														null,
														"error"
													);
												}
											}
										});
								} else {
									if (res.response_code === 10055) {
										this.$swal
											.fire({
												title: "Congratulation",
												html:
													"You have completed the course",
												confirmButtonText: "OK",
												heightAuto: false,
												showCancelButton: false,
												customClass: {
													container:
														"confirmation-swal",
													header: "bg-info",
													confirmButton:
														"btn btn-sm btn-primary ml-3",
													cancelButton:
														"btn btn-sm btnーsecondary ml-3",
												},
											})
											.then((result) => {
												if (result.isConfirmed) {
													_this.$router.push({
														name: "trainee-course",
														params: {
															traineeCourseId: this
																.$route.params
																.traineeCourseId,
														},
													});
												}
											});
									} else {
										SystemHelper.errorHandler(
											res,
											null,
											"error"
										);
									}
								}
							});
					}
				});
		},
		playerReadied() {
			this.playbackAtStart = true;
		},
		updatePaused(event) {
			this.videoElement = event.target;
			this.paused = event.target.paused;
		},
		play() {
			this.playbackAtStart = false;
			this.playbackEnded = false;
			this.videoElement.play();
		},
		pause() {
			this.videoElement.pause();
		},
		onPlayerEnded() {
			this.playbackEnded = true;
			// window.alert("ended");
		},
		onPlayerTimeupdate() {},
	},
	beforeDestroy() {
		if (this.player) {
			this.player.dispose();
		}
	},
};
</script>

<style>
@import "https://vjs.zencdn.net/7.11.4/video-js.css";

.video-js .vjs-big-play-button {
	display: none;
}
.glow {
	text-shadow: 1px 1px 10px #000;
}
</style>
