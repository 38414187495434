<template>
	<component :is="tag">
		<div class="lesson-wrapper">
			<div class="container pb-6">
				<div class="">
					<div class="position-relative">
						<b-progress
							:animated="true"
							v-if="loadedRatio > 0 && loadedRatio < 1"
							:value="loadedRatio * 100"
							variant="warning"
							striped
							class="mt-2 rounded-0"
						></b-progress>
						<pdf
							ref="pdf"
							:src="pdfSrc"
							:page="currentPage"
							@progress="loadedRatio = $event"
							@error="error"
							@loaded="checkLoaded"
							@page-loaded="pageLoaded($event)"
							@num-pages="pageCount = $event"
							@link-clicked="pageCount = $event"
						></pdf>
					</div>
					<b-row class="my-6">
						<b-col>
							<b-button-group>
								<b-button
									:disabled="currentPage === 1"
									@click="goPrevious"
									>Prev</b-button
								>
								<div>
									<input
										style="border-radius: 0"
										type="text"
										class="
											form-control
											text-center
											border-0
										"
										disabled
										:value="
											`${currentPage} of ${pageCount}`
										"
									/>
								</div>
								<b-button
									:disabled="currentPage === pageCount"
									@click="goNext"
									>Next</b-button
								>
							</b-button-group>
						</b-col>
						<b-col cols="auto">
							<b-button-group>
								<b-button
									variant="primary"
									@click="submitLesson"
									v-if="
										currentPage === pageCount &&
											!materialCompleted &&
											!lessonCompleted
									"
									>Complete Lesson</b-button
								>
								<b-button
									variant="primary"
									@click="backToCourse"
									v-if="materialCompleted || lessonCompleted"
									>Back to Course</b-button
								>
							</b-button-group>
						</b-col>
					</b-row>
				</div>
				<b-row class="mb-6">
					<b-col lg="8" sm="12">
						<h2 class="text-md-h4 text-white">
							{{ materialTitle }}
						</h2>
						<p class="text-white" v-html="materialDescription"></p>
					</b-col>
				</b-row>
			</div>
		</div>

		<!-- modals -->
		<course-pledge-modal
			:course-id="$route.params.traineeCourseId"
			@agree="completeLesson()"
		></course-pledge-modal>
	</component>
</template>

<script>
import SystemHelper from "@/core/services/systemhelper.service";

import pdf from "vue-pdf";
import {
	// FORWARD_NEXT_MATERIAL,
	GET_VIEW_MATERIAL,
	START_RESUME_COURSE,
	SUBMIT_COURSE_PROGRESS,
	SAVE_FINAL_CHECK_DATA,
} from "@/core/services/store/modules/trainee/course.module";
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";

import CoursePledgeModal from "@/view/pages/traineeuser/modals/CoursePledgeModal.vue";

export default {
	name: "PdfComponent",
	components: { pdf, CoursePledgeModal },
	mixins: [],
	data() {
		return {
			currentPage: 1,
			pageCount: 0,
			loadedRatio: 0,
		};
	},
	props: {
		materialCompleted: { required: true },
		traineeCourseId: { required: true },
		lessonCompleted: { required: true },
		// trainingObj: { type: Object, required: true },
		tag: {
			type: String,
			default: "div",
		},
	},
	mounted() {
		let payload = {
			material_id: this.$route.params.lessonId,
		};
		this.$store.dispatch(GET_VIEW_MATERIAL, payload);
	},
	created() {},
	methods: {
		backToCourse() {
			this.$router.push({
				name: "trainee-course",
				traineeCourseId: this.traineeCourseId,
			});
		},
		continueCourse() {
			let payload = {
				trainee_course_id: this.$route.params.traineeCourseId,
			};
			this.$store.dispatch(START_RESUME_COURSE, payload).then((res) => {
				if (res.response_code === 2100) {
					if (res.response_code === 2100) {
						let data = res.data.output;
						console.log("data", data);

						let localArray = this.CourseProgressObj;

						let result = localArray.filter((module) => {
							return module.trainee_module_items.some(
								(trainee_module_item) => {
									return (
										trainee_module_item.trainee_module_item_id ===
										data.trainee_module_item_id
									);
								}
							);
						});

						let pushParams;
						if (result.material_id) {
							pushParams = {
								lessonType: result.material_detail.file_type,
								lessonId: result.material_detail.id,
								lessonObj: result.material_detail,
							};
						} else {
							pushParams = {
								lessonType: "quiz",
								lessonId: data.quiz_id,
							};
						}

						this.$router.push({
							name: "trainee-lesson",
							params: {
								traineeCourseId: this.$route.params
									.traineeCourseId,
								...pushParams,
							},
						});

						console.log("result", result);
					} else {
						SystemHelper.errorHandler(res, null, "error");
					}
				}
			});
		},
		error: function(err) {
			console.log(err);
		},
		checkLoaded() {
			console.log("ready");
		},
		pageLoaded(event) {
			console.log(event);
			console.log("page ready");
		},
		submitLesson() {
			let isFinalModule = this.$parent.isFinalModule;
			let isFinalLesson = this.$parent.isFinalLesson;
			if (isFinalModule && isFinalLesson) {
				this.openModal("modal-course-pledge");
			} else {
				this.completeLesson();
			}
		},
		async completeLesson() {
			let payload = {
				trainee_course_id: this.traineeCourseId,
				trainee_module_progress_id: this.courseProgressObj
					.trainee_module_progress_id,
			};

			await this.$swal
				.fire({
					title: "Complete Lesson",
					html: "Are you sure?",
					confirmButtonText: "OK",
					heightAuto: false,
					showCancelButton: false,
					customClass: {
						container: "confirmation-swal",
						header: "bg-info",
						confirmButton: "btn btn-sm btn-primary ml-3",
						cancelButton: "btn btn-sm btnーsecondary ml-3",
					},
				})
				.then((result) => {
					if (result.isConfirmed) {
						let _this = this;
						this.$store
							.dispatch(SUBMIT_COURSE_PROGRESS, payload)
							.then((res) => {
								console.log("res", res);
								if (res.response_code === 2100) {
									let payload = {
										trainee_course_id: this.$route.params
											.traineeCourseId,
									};
									this.$store
										.dispatch(START_RESUME_COURSE, payload)
										.then((res) => {
											if (res.response_code === 2100) {
												if (
													res.response_code === 2100
												) {
													let data = res.data.output;
													console.log("data", data);

													// let localArray = this.CourseProgressObj;
													//
													// let result = localArray.filter((module) => {
													//   return module.trainee_module_items.some(
													//     (trainee_module_item) => {
													//       return (
													//         trainee_module_item.trainee_module_item_id ===
													//         data.trainee_module_item_id
													//       );
													//     }
													//   );
													// });

													let pushParams;
													if (data.material_id) {
														pushParams = {
															lessonType:
																data
																	.material_detail
																	.file_type,
															lessonId:
																data.material_id,
															lessonObj: {
																...data.material_detail,
																trainee_module_progress_id:
																	data.trainee_module_progress_id,
															},
														};
													} else {
														pushParams = {
															lessonType: "quiz",
															lessonId:
																data.quiz_id,
														};
													}

													// save finalCheckData
													this.$store.dispatch(
														SAVE_FINAL_CHECK_DATA,
														{
															trainee_module_item_id:
																data.trainee_module_item_id,
															trainee_module_id:
																data.trainee_module_id,
														}
													);

													_this.$router.push({
														name: "trainee-lesson",
														params: {
															traineeCourseId: this
																.$route.params
																.traineeCourseId,
															...pushParams,
														},
													});
												}
											} else {
												if (
													res.response_code === 10055
												) {
													this.$swal
														.fire({
															title:
																"Congratulation",
															html:
																"You have completed the course",
															confirmButtonText:
																"OK",
															heightAuto: false,
															showCancelButton: false,
															customClass: {
																container:
																	"confirmation-swal",
																header:
																	"bg-info",
																confirmButton:
																	"btn btn-sm btn-primary ml-3",
																cancelButton:
																	"btn btn-sm btnーsecondary ml-3",
															},
														})
														.then((result) => {
															if (
																result.isConfirmed
															) {
																_this.$router.push(
																	{
																		name:
																			"trainee-course",
																		params: {
																			traineeCourseId: this
																				.$route
																				.params
																				.traineeCourseId,
																		},
																	}
																);
															}
														});
												} else {
													SystemHelper.errorHandler(
														res,
														null,
														"error"
													);
												}
											}
										});
								} else {
									if (res.response_code === 10055) {
										this.$swal
											.fire({
												title: "Congratulation",
												html:
													"You have completed the course",
												confirmButtonText: "OK",
												heightAuto: false,
												showCancelButton: false,
												customClass: {
													container:
														"confirmation-swal",
													header: "bg-info",
													confirmButton:
														"btn btn-sm btn-primary ml-3",
													cancelButton:
														"btn btn-sm btnーsecondary ml-3",
												},
											})
											.then((result) => {
												if (result.isConfirmed) {
													_this.$router.push({
														name: "trainee-course",
														params: {
															traineeCourseId: this
																.$route.params
																.traineeCourseId,
														},
													});
												}
											});
									} else {
										SystemHelper.errorHandler(
											res,
											null,
											"error"
										);
									}
								}
							});
					}
				});
			// SystemHelper.confirmationDialogHandler(
			//   { title: "Confirmation", html: "Complete Lesson?" },
			//   {
			//     action: SUBMIT_COURSE_PROGRESS,
			//     payload,
			//   },
			//   [
			//     {
			//       action: FORWARD_NEXT_MATERIAL,
			//       payload: { trainee_course_id: this.traineeCourseId },
			//     },
			//   ],
			//   null
			// );
		},
		goNext() {
			this.currentPage = this.currentPage + 1;
		},
		goPrevious() {
			this.currentPage = this.currentPage - 1;
		},
	},
	beforeDestroy() {},
	computed: {
		courseProgressObj() {
			let x = this.$store.state.trainee_course.courseProgressObj;
			if (x) {
				return x;
			} else {
				return {};
			}
		},
		materialTitle() {
			let trainingLessonObj = this.$store.state.trainee_course
				.materialDetail;
			if (trainingLessonObj) {
				return trainingLessonObj.material_name;
			} else {
				return "Error";
			}
		},
		materialDescription() {
			let trainingLessonObj = this.$store.state.trainee_course
				.materialDetail;
			if (trainingLessonObj) {
				this.$store.dispatch(SET_BREADCRUMB, [
					{ title: "Lesson" },
					{ title: trainingLessonObj.material_name },
				]);
				return trainingLessonObj.material_name;
			} else {
				return "Error";
			}
		},
		pdfSrc() {
			let trainingLessonObj = this.$store.state.trainee_course
				.materialDetail;
			if (trainingLessonObj) {
				return trainingLessonObj.link;
			} else {
				return "Error";
			}
		},
	},
};
</script>
