var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative"},[_c('video',{ref:"videoPlayer",staticClass:"video-js vjs-fluid",attrs:{"id":"videoElement","controls":"","poster":_vm.parseVidPoster},on:{"canplay":_vm.updatePaused,"playing":_vm.updatePaused,"pause":_vm.updatePaused,"ended":function($event){return _vm.onPlayerEnded($event)},"ready":_vm.playerReadied,"timeupdate":function($event){return _vm.onPlayerTimeupdate($event)}}},[[_c('source',{attrs:{"special":"","src":_vm.parseVidSrc,"type":"video/mp4"}})],_c('p',[_vm._v(" Sorry, there's a problem playing this video. Please try using a different browser. ")])],2),(_vm.playbackEnded)?_c('div',{staticClass:"\n        video-overlay\n        position-absolute\n        zindex-5\n        left-0\n        right-0\n        top-0\n        bottom-0\n      ",staticStyle:{"background-color":"rgba(0, 0, 0, 0.5)"}},[_c('div',{staticClass:"controls text-center w-100 position-absolute",staticStyle:{"top":"40%"}},[_c('h4',{staticClass:"p-5 glow"},[_vm._v("You have completed the material")]),_c('b-button',{staticClass:"h4",on:{"click":_vm.play}},[_vm._v(" Replay Lesson ")]),(
						_vm.materialCompleted ||
							!_vm.materialProgressStatus.course_progress_status_id ||
							_vm.materialProgressStatus.course_progress_status_id ===
								1 ||
							_vm.lessonCompleted
					)?_c('b-button',{staticClass:"h4 ml-3",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.backToCourse()}}},[_vm._v("Back to Course page ")]):_vm._e(),(
						(!_vm.materialCompleted ||
							_vm.materialProgressStatus.course_progress_status_id ===
								2 ||
							_vm.materialProgressStatus.course_progress_status_id ===
								3) &&
							!_vm.lessonCompleted
					)?_c('b-button',{staticClass:"h4 ml-3",attrs:{"variant":"primary"},on:{"click":_vm.submitLesson}},[_vm._v("Complete Lesson ")]):_vm._e()],1)]):_vm._e(),(_vm.paused && !_vm.playbackEnded)?_c('div',{staticClass:"\n        video-overlay\n        position-absolute\n        zindex-5\n        left-0\n        right-0\n        top-0\n        bottom-0\n      ",staticStyle:{"background-color":"rgba(0, 0, 0, 0.5)"}},[(!_vm.playbackAtStart)?_c('div',{staticClass:"controls text-center w-100 position-absolute",staticStyle:{"top":"40%"}},[_c('h4',{staticClass:"text-light p-5 glow"},[_vm._v("Taking a break?")]),_c('b-button',{staticClass:"h4",on:{"click":_vm.play}},[_vm._v(" Play "),_c('b-icon',{attrs:{"variant":"dark","size":"lg","icon":"play-circle-fill"}})],1)],1):_vm._e(),(_vm.playbackAtStart)?_c('div',{staticClass:"controls text-center w-100 position-absolute",staticStyle:{"top":"40%"}},[_c('h4',{staticClass:"text-light p-5 glow"},[_vm._v("Ready to learn new lesson?")]),_c('b-button',{staticClass:"h4",on:{"click":_vm.play}},[_vm._v(" Play "),_c('b-icon',{attrs:{"variant":"dark","size":"lg","icon":"play-circle-fill"}})],1)],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"d-none"},[_vm._v(" "+_vm._s(_vm.materialDetail)+" ")]),_c('course-pledge-modal',{attrs:{"course-id":_vm.$route.params.traineeCourseId},on:{"agree":function($event){return _vm.completeLesson()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }