<template>
	<component :is="tag">
		<div class="container">
			<!--  begin: Wizard-->
			<div
				class="wizard wizard-4 pt-6"
				id="kt_wizard_v4"
				data-wizard-state="step-first"
				data-wizard-clickable="false"
			>
				<!--begin: Wizard Nav-->
				<div class="wizard-nav">
					<div class="wizard-steps">
						<div
							v-for="(question, i) in trainingObj.question_list"
							class="wizard-step"
							data-wizard-type="step"
							:data-wizard-state="{ current: i === 0 }"
							:key="`question-${i + 1}`"
						>
							<div class="wizard-wrapper">
								<div class="wizard-number">Q{{ i + 1 }}</div>
							</div>
						</div>
						<div
							v-if="quizResult"
							class="wizard-step"
							data-wizard-type="step"
							:data-wizard-state="{ current: quizResult }"
						>
							<div class="wizard-wrapper">
								<div class="wizard-label result">
									<div class="wizard-title">Result</div>
									<div class="wizard-desc">Your Score</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!--end: Wizard Nav -->

				<!--begin: Wizard Body-->
				<div
					class="
						card
						bg-transparent
						card-custom card-shadowless
						rounded-top-0
					"
				>
					<div class="card-body p-0">
						<div
							class="
								row
								justify-content-center
								py-8
								px-8
								py-lg-15
								px-lg-10
							"
						>
							<div class="col-xl-12 col-xxl-7">
								<!--begin: Wizard Form-->
								<form class="form mt-0 mt-lg-10" id="kt_form">
									<!--begin: Wizard Step 1-->
									<template
										v-for="(question,
										i) in trainingObj.question_list"
									>
										<div
											:key="
												`question-${i + 1}-${
													question.question_id
												}`
											"
											class="wizard-step"
											data-wizard-type="step-content"
											data-wizard-state="current"
										>
											<h6
												class="
													mb-5
													quiz-name
													text-white
												"
											>
												{{ trainingObj.quiz_name }}
												(Question {{ i + 1 }})
											</h6>
											<h2
												class="question-name text-white"
											>
												{{ question.question_name }}
											</h2>
											<b-form-radio-group
												buttons
												stacked
												@change.native="
													radioOnSelect(
														i,
														question.question_id,
														selected[i]
													)
												"
												size="lg"
												button-variant="primary"
												class="answer-group"
												:id="
													`question-${i + 1}-${
														question.question_id
													}`
												"
												v-model="selected[i]"
												name="radio-sub-component"
											>
												<template
													v-for="(answersOption,
													x) in question.answer_list"
													><b-form-radio
														:value="{
															question_id:
																question.question_id,
															user_submission_answer_option_id:
																answersOption.answer_option_id,
														}"
														:key="
															`q-${i}-${x}-ans-${answersOption.answer_option_id}`
														"
													>
														{{
															answersOption.value
														}}</b-form-radio
													>
												</template>
											</b-form-radio-group>
										</div>
									</template>
									<template v-if="quizResult">
										<div
											class="wizard-step"
											data-wizard-type="step-content"
											data-wizard-state="current"
										>
											<h4>Submission</h4>
											<pre class="text-white">{{
												selected
											}}</pre>
											<h4>Result</h4>
										</div>
									</template>

									<!--end: Wizard Step 1-->
								</form>
								<!--end: Wizard Form-->
							</div>
						</div>
					</div>
				</div>
				<!--end: Wizard Bpdy-->

				<!--begin: Wizard Actions -->
				<div class="d-flex justify-content-between pt-10">
					<div class="mr-2">
						<button
							v-if="trainingObj.question_list > 0"
							class="
								btn btn-light-primary
								font-weight-bold
								text-uppercase
								px-9
								py-4
							"
							data-wizard-type="action-prev"
						>
							Previous Question
						</button>
					</div>
					<div>
						<button
							v-if="!materialCompleted && !lessonCompleted"
							v-on:click="submitLesson"
							class="
								btn btn-success
								font-weight-bold
								text-uppercase
								px-9
								py-4
							"
							data-wizard-type="action-submit"
						>
							Submit
						</button>
						<button
							v-if="materialCompleted || lessonCompleted"
							v-on:click="backToCourse"
							class="
								btn btn-success
								font-weight-bold
								text-uppercase
								px-9
								py-4
							"
							data-wizard-type="action-submit"
						>
							Back to Course
						</button>
						<button
							class="
								btn btn-primary
								font-weight-bold
								text-uppercase
								px-9
								py-4
							"
							data-wizard-type="action-next"
						>
							Next Question
						</button>
					</div>
				</div>
				<!--end: Wizard Actions -->
			</div>
			<!--  end: Wizard-->

			<div style="height: 4rem"></div>
			<b-row>
				<b-col cols="8" class="mb-6">
					<h2 class="text-sm-h4 text-white">
						{{ trainingObj.quiz_name }}
					</h2>
					<p class="text-white" v-html="trainingObj.description"></p>
				</b-col>
			</b-row>
		</div>
		<div class="d-none">
			<pre>materialCompleted {{ materialCompleted }}</pre>
			<pre>traineeModuleProgress {{ traineeModuleProgress }}</pre>
			<pre>selected {{ selected }}</pre>
			<pre>{{ materialDetail }}</pre>
			<pre>trainingObj {{ trainingObj }}</pre>
		</div>

		<!-- modals -->
		<course-pledge-modal
			:course-id="$route.params.traineeCourseId"
			@agree="submit()"
		></course-pledge-modal>
	</component>
</template>

<script>
import KTWizard from "@/assets/js/components/wizard";
import KTUtil from "@/assets/js/components/util";
import {
	GET_VIEW_QUIZ,
	START_RESUME_COURSE,
	SUBMIT_COURSE_PROGRESS,
	SAVE_FINAL_CHECK_DATA,
} from "@/core/services/store/modules/trainee/course.module";
import SystemHelper from "@/core/services/systemhelper.service";
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";

import CoursePledgeModal from "@/view/pages/traineeuser/modals/CoursePledgeModal.vue";

export default {
	name: "QuizComponent",
	components: { CoursePledgeModal },
	mixins: [],
	data() {
		return {
			selected: [],
			submission: [],
			quizResult: false,
		};
	},
	async mounted() {
		this.lessonType = "quiz";
		let payload = {
			quiz_id: this.$route.params.lessonId,
		};
		await this.$store.dispatch(GET_VIEW_QUIZ, payload).then((res) => {
			if (res.response_code === 2100) {
				// Initialize form wizard
				const wizard = new KTWizard("kt_wizard_v4", {
					startStep: 1, // initial active step number
					clickableSteps: true, // allow step clicking
				});

				// Validation before going to next page
				wizard.on("beforeNext", function(/*wizardObj*/) {
					// validate the form and use below function to stop the wizard's step
					// wizardObj.stop();
				});

				// Change event
				wizard.on("change", function(/*wizardObj*/) {
					setTimeout(() => {
						KTUtil.scrollTop();
					}, 500);
				});

				this.$store.dispatch(SET_BREADCRUMB, [
					{ title: "Quiz" },
					{ title: res.data.quiz_detail.quiz_name },
				]);
			}
		});
	},
	props: {
		materialCompleted: { required: true },
		lessonCompleted: { required: true },
		traineeCourseId: { required: true },
		trainingObj: { type: Object, required: true },
		tag: {
			type: String,
			default: "div",
		},
	},
	methods: {
		backToCourse() {
			this.$router.push({
				name: "trainee-course",
				traineeCourseId: this.traineeCourseId,
			});
		},
		radioOnSelect(q, qid, ans) {
			console.log("clicked");
			this.submission[q] = {
				qid: qid,
				ans: ans,
			};
		},
		submitLesson() {
			let isFinalModule = this.$parent.isFinalModule;
			let isFinalLesson = this.$parent.isFinalLesson;
			if (isFinalModule && isFinalLesson) {
				this.openModal("modal-course-pledge");
			} else {
				this.submit();
			}
		},
		async submit() {
			// e.preventDefault();
			await this.$swal
				.fire({
					title: "Complete Lesson",
					html: "Are you sure?",
					confirmButtonText: "OK",
					heightAuto: false,
					showCancelButton: false,
					customClass: {
						container: "confirmation-swal",
						header: "bg-info",
						confirmButton: "btn btn-sm btn-primary ml-3",
						cancelButton: "btn btn-sm btnーsecondary ml-3",
					},
				})
				.then((result) => {
					if (result.isConfirmed) {
						let _this = this;
						let payload = {
							trainee_course_id: this.traineeCourseId,
							trainee_module_progress_id: this.courseProgressObj
								.trainee_module_progress_id,
							quiz_id: this.trainingObj.quiz_id,
							submissions_quiz: JSON.stringify(this.selected),
						};
						this.$store
							.dispatch(SUBMIT_COURSE_PROGRESS, payload)
							.then((res) => {
								console.log("res", res);
								if (res.response_code === 2100) {
									if (res.data.userPassStatus) {
										let payload = {
											trainee_course_id: this.$route
												.params.traineeCourseId,
										};
										this.$store
											.dispatch(
												START_RESUME_COURSE,
												payload
											)
											.then((res) => {
												if (
													res.response_code === 2100
												) {
													if (
														res.response_code ===
														2100
													) {
														let data =
															res.data.output;
														console.log(
															"data",
															data
														);

														let pushParams;
														if (data.material_id) {
															pushParams = {
																lessonType:
																	data
																		.material_detail
																		.file_type,
																lessonId:
																	data.material_id,
																lessonObj: {
																	...data.material_detail,
																	trainee_module_progress_id:
																		data.trainee_module_progress_id,
																},
															};
														} else {
															pushParams = {
																lessonType:
																	"quiz",
																lessonId:
																	data.quiz_id,
															};
														}

														// save finalCheckData
														this.$store.dispatch(
															SAVE_FINAL_CHECK_DATA,
															{
																trainee_module_item_id:
																	data.trainee_module_item_id,
																trainee_module_id:
																	data.trainee_module_id,
															}
														);

														_this.$router.push({
															name:
																"trainee-lesson",
															params: {
																traineeCourseId: this
																	.$route
																	.params
																	.traineeCourseId,
																...pushParams,
															},
														});
													}
												} else {
													if (
														res.response_code ===
														10055
													) {
														this.$swal
															.fire({
																title:
																	"Congratulation",
																html:
																	"You have completed the course",
																confirmButtonText:
																	"OK",
																heightAuto: false,
																showCancelButton: false,
																customClass: {
																	container:
																		"confirmation-swal",
																	header:
																		"bg-info",
																	confirmButton:
																		"btn btn-sm btn-primary ml-3",
																	cancelButton:
																		"btn btn-sm btnーsecondary ml-3",
																},
															})
															.then((result) => {
																if (
																	result.isConfirmed
																) {
																	_this.$router.push(
																		{
																			name:
																				"trainee-course",
																			params: {
																				traineeCourseId: this
																					.$route
																					.params
																					.traineeCourseId,
																			},
																		}
																	);
																}
															});
													} else {
														SystemHelper.errorHandler(
															res,
															null,
															"error"
														);
													}
												}
											});
									} else {
										SystemHelper.basicSwalHandler(
											"Quiz Failed",
											"Unfortunately, you didn't passed the quiz. Please review your answer and try again.",
											"warning"
										);
									}
								} else {
									if (res.response_code === 10055) {
										this.$swal
											.fire({
												title: "Congratulation",
												html:
													"You have completed the course",
												confirmButtonText: "OK",
												heightAuto: false,
												showCancelButton: false,
												customClass: {
													container:
														"confirmation-swal",
													header: "bg-info",
													confirmButton:
														"btn btn-sm btn-primary ml-3",
													cancelButton:
														"btn btn-sm btnーsecondary ml-3",
												},
											})
											.then((result) => {
												if (result.isConfirmed) {
													_this.$router.push({
														name: "trainee-course",
														params: {
															traineeCourseId: this
																.$route.params
																.traineeCourseId,
														},
													});
												}
											});
									} else {
										SystemHelper.errorHandler(
											res,
											null,
											"error"
										);
									}
								}
							});
					}
				});

			// if (this.selected.length !== this.trainingObj.question_list.length) {
			//   SystemHelper.basicSwalHandler(
			//     "Validation Error",
			//     "You have not answer all the question. Please check again.",
			//     "danger"
			//   );
			//   return;
			// } else {
			//   SystemHelper.confirmationDialogHandler(
			//     { title: "Confirmation", html: "Submit Quiz?" },
			//     { action: SUBMIT_COURSE_PROGRESS, payload },
			//     null,
			//     ""
			//   );
			// }
		},
	},
	computed: {
		materialDetail() {
			let material = this.$store.state.trainee_course.currentActiveLesson;
			if (material) {
				return material;
			} else {
				return {};
			}
		},
		traineeModuleProgress() {
			let material = this.$store.state.trainee_course
				.traineeModuleProgress;
			if (material) {
				return material;
			} else {
				return {};
			}
		},
		courseProgressObj() {
			let x = this.$store.state.trainee_course.courseProgressObj;

			return x;
		},
		quizDetails() {
			let x = this.$store.state.trainee_course.quizQuestionList;
			if (x) {
				return x;
			} else {
				return {};
			}
		},
	},
};
</script>
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
</style>
