<template>
	<component :is="tag">
		<div class="container">
			<div class="mb-6">
				<video-player
					:material-completed="materialCompleted"
					:lesson-completed="lessonCompleted"
					:trainee-course-id="traineeCourseId"
					:material-id="materialId"
					class="w-100"
				/>
			</div>
			<b-row>
				<b-col lg="8" sm="12" class="mb-6">
					<h2 class="text-lg-h4 text-white">{{ materialTitle }}</h2>
					<p class="text-white" v-html="materialDescription"></p>
				</b-col>
			</b-row>
		</div>
	</component>
</template>

<script>
import VideoPlayer from "@/view/layout/extras/videojs/VideoPlayer.vue";
import { GET_VIEW_MATERIAL } from "@/core/services/store/modules/trainee/course.module";

export default {
	name: "VideoComponent",
	components: { VideoPlayer },
	mixins: [],
	data() {
		return {};
	},
	props: {
		materialCompleted: { required: true },
		lessonCompleted: { required: true },
		materialId: { required: true },
		traineeCourseId: { required: true },
		tag: {
			type: String,
			default: "div",
		},
	},
	async mounted() {
		let payload = {
			material_id: this.$route.params.lessonId,
		};
		await this.$store.dispatch(GET_VIEW_MATERIAL, payload);
	},
	methods: {},
	computed: {
		materialTitle() {
			let trainingLessonObj = this.$store.state.trainee_course
				.materialDetail;
			if (trainingLessonObj) {
				return trainingLessonObj.material_name;
			} else {
				return "Error";
			}
		},
		materialDescription() {
			let trainingLessonObj = this.$store.state.trainee_course
				.materialDetail;
			if (trainingLessonObj) {
				return trainingLessonObj.description;
			} else {
				return "Error";
			}
		},
	},
};
</script>
